import { TokenPayload } from '@recurly/recurly-js'

declare global {
  interface Window {
    dataLayer: any
  }
}

export function isError(v: any): v is Error {
  return v instanceof Error
}

export interface SubscriptionChangeType {
  subscriptionId: string
  planCode: string
  planName: string
  unitAmount: number
  activated: boolean
  activateAt: string
}

export enum PlanCode {
  ARCADE_BUNDLE = 'reasonplusarcadeannual',
  MONTHLY = 'reasonplusmonthly',
  ANNUAL = 'reasonplusannualnt',
}

export interface BaseSubscriptionType {
  planCode: PlanCode
  planName: string
  currency: string
  price: string
}

export interface SubscriptionType extends BaseSubscriptionType {
  id: string
  state: SubscriptionStatus
  expiryDate: string
  activatedAt: string
  isTrial: boolean
  pendingChange: SubscriptionChangeType | null
  arcadeLink?: string
}

export interface SubscriptionTypeResponse extends SubscriptionType {
  threedsActionRequired?: boolean
  threedsToken?: string
  error?: string
  couponCode?: string
  couponName?: string
}

export interface CreateSubscriptionPayload {
  plan: string
  currency: string
  couponCode?: string
  billingInfo: TokenPayload
}

enum InvoiceState {
  OPEN = 'open',
  PENDING = 'pending',
  PROCESSING = 'processing',
  PAST_DUE = 'past_due',
  PAID = 'paid',
  CLOSED = 'closed',
  FAILED = 'failed',
  VOIDED = 'voided',
}

export interface Invoice {
  invoiceId?: string
  invoiceType: string
  invoiceNumber?: string
  state: InvoiceState
  subscriptionIds: [string]
  total: string
  currency: string
  dueAt: string
  creditsApplied: boolean
}

export enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  PAYPAL = 'paypal_billing_agreement',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  OTHER = 'other',
}

export interface BillingInfo {
  paymentMethod: PaymentMethod
  ccDetails?: CreditCardDetails
}

export interface CreditCardDetails {
  lastFour: number
  cardType: string
  expiryMonth: number
  expiryYear: number
}

export interface RecurlyAccount {
  code: string
  email: string
  firstName?: string
  lastName?: string
  country: string
  subscriptionStatus: SubscriptionStatus
  billingInfo?: BillingInfo
  hasAntichurned?: boolean
  canAntichurnEarly?: boolean
  canAntichurnLate?: boolean
  userId?: string
}

export interface ReasonAccount {
  firstName: string
  lastName: string
  email: string
  currency?: string
  username: string
  addressCountryCode: string
  autoCountryCode: string
  addressLine1: string
  addressLine2: string
  addressZip: string
  addressCity: string
  addressState: string
  hasTakenPlusTrial?: boolean
  groups: string[]
}

export function castToReasonAccount(x: any): ReasonAccount {
  const nullToString = (val: any): string => (val === null ? '' : val)

  return {
    firstName: x.firstName,
    lastName: x.lastName,
    email: x.email,
    currency: nullToString(x.currency),
    username: x.username,
    addressCountryCode: nullToString(x.addressCountryCode),
    autoCountryCode: nullToString(x.autoCountryCode),
    addressLine1: nullToString(x.addressLine1),
    addressLine2: nullToString(x.addressLine2),
    addressZip: nullToString(x.addressZip),
    addressCity: nullToString(x.addressCity),
    addressState: nullToString(x.addressState),
    hasTakenPlusTrial: x.hasTakenPlusTrial,
    groups: x.groups || [],
  }
}

export enum CouponState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  REDEEMABLE = 'redeemable',
}

export enum CouponRedemptionResource {
  ACCOUNT = 'account',
  SUBSCRIPTION = 'subscription',
}

export interface Coupon {
  code: string
  id: string
  claimedAt: string
  state: CouponState
  discountedSum: number
  currency: string
  displayText: string
  appliesToAllPlans?: boolean
  planCodes?: [string]
}

export interface Entitlement {
  userId: number
  hardwareId: string
  lastOnline: string
  isLongterm: boolean
  isUnlockRequested: boolean
  computerName: string
  expiryDate: string
  serialPrefix: string
}
