import countries from './data/countries.json'
import { RecurlyAccount } from './types/types'

export const resolve2LetterCountry = (code: string) => {
  const country = countries.find((c) => c.threeLetterCode === code)
  if (!country) {
    throw new Error(`Cant convert country code ${code} to a two letter code`)
  }

  return country.code
}

export const resolveCountryName = (code: string) => {
  const country = countries.find((c) => c.threeLetterCode === code)
  if (!country) {
    throw new Error(`Cant convert country code ${code} to a pretty string`)
  }
  return country.name
}

export const formatCurrency = (
  amount: string | number,
  currency: string,
): string => {
  const parsed = typeof amount === 'string' ? Number.parseFloat(amount) : amount

  if (!Number.isFinite(parsed)) {
    throw new Error(`Could not parse amount ${amount}`)
  }

  return parsed
    .toLocaleString(undefined, {
      style: 'currency',
      currency,
      minimumFractionDigits: parsed % 1 === 0 ? 0 : 2,
    })
    .replace(/(US)|(JP)/, '')
    .replace(/(\W)\s+/, '$1')
}

export const formatDate = (date: string, withTime = false): string => {
  const d = new Date(date)
  if (Number.isNaN(d.getTime())) {
    throw new Error(`Could not parse date ${date}`)
  }

  let options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  if (withTime) {
    options = { ...options, hour: 'numeric', minute: 'numeric' }
  }
  return new Date(date).toLocaleString('en-US', options)
}

export const formatSerialPrefix = (sp: string): string => {
  switch (sp) {
    case 'RS1200':
      return 'Reason 12'
    default:
      return sp
  }
}

export const reformatCouponErrors = (message: string) => {
  switch (message) {
    case 'has reached max redemptions for this account, Coupon has been maxed out':
      return 'You can only use this coupon once'
    case 'has reached max redemptions for this account':
      return 'You can only use this coupon once'
    default:
      console.error('Coupon error:', message)
      return 'There was an error trying to apply the coupon'
  }
}

export const getPlanArticle = (plan: string) => {
  if (plan.toLowerCase().includes('monthly')) {
    return 'a'
  } else {
    return 'an'
  }
}

export const formatPlanName = (plan?: string) => {
  if (plan) {
    return plan.replace('NO TRIAL', '').replace('Subscription', '').trim()
  } else {
    return ''
  }
}
export const isRecurlyAccount = (account: any): account is RecurlyAccount => {
  return account && account.billingInfo?.ccDetails
}
export const isAccountDetailsValid = (
  account: RecurlyAccount | Error | null,
) => {
  if (!isRecurlyAccount(account)) {
    return false
  }
  const { expiryYear, expiryMonth } = account.billingInfo?.ccDetails!
  return isCardDetailsValid(expiryYear, expiryMonth)
}

export const isCardDetailsValid = (expiryYear: number, expiryMonth: number) => {
  const expiryDate = new Date(`${expiryYear}-${expiryMonth}`)
  const cardExpiry = expiryDate.getTime()

  if (Number.isNaN(cardExpiry)) {
    return false
  }

  return Date.now() < cardExpiry
}

export const getCookie = (name: string) => {
  let cookieValue = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}
