import jwtDecode, { JwtPayload } from 'jwt-decode'
import Axios, { AxiosError, AxiosResponse } from 'axios'
import { UnauthorizedError } from './Errors'

export interface rsJwtPayload {
  user_id: string
  is_admin: boolean
}

type extendedJwtPayload = JwtPayload & rsJwtPayload

let userId: string

export const getUserId = () => {
  if (!userId) {
    throw new UnauthorizedError()
  }
  return userId
}

export const getIsAdmin = () => {
  return (
    !!jwt?.accessToken &&
    jwtDecode<extendedJwtPayload>(jwt.accessToken).is_admin
  )
}

interface jwtType {
  accessToken: string
  refreshToken: string
}

let jwt: jwtType

export const getJwt = () => {
  return jwt
}

export const getTokenFromClown = async () => {
  const res = await Axios.post(
    `${process.env.NEXT_PUBLIC_CLOWN_URL}/account/token`,
    {},
    { withCredentials: true },
  )
    .then((res: AxiosResponse) => {
      jwt = res.data
      userId = jwtDecode<extendedJwtPayload>(res.data.accessToken).user_id
      return res.data.accessToken
    })
    .catch((error: AxiosError) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 400)
      ) {
        location.href = `${process.env.NEXT_PUBLIC_CLOWN_URL}/account/login?next=${location}`
      } else if (typeof error.response === 'undefined') {
        // Most likely a CORS error (sending no ph_sessionid cookie) - redirect to login and return
        location.href = `${process.env.NEXT_PUBLIC_CLOWN_URL}/account/login?next=${location}`
      } else {
        throw error
      }
    })
  return res
}
