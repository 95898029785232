import { getTokenFromClown } from './TokenClient'
import { ForbiddenError, UnauthorizedError } from './Errors'

export function requestWrapper<T>(fn: (...args: Array<any>) => Promise<T>) {
  const fnWithRetry = async (
    retry: boolean,
    ...args: any
  ): Promise<T | undefined> => {
    try {
      return await fn(...args)
    } catch (error) {
      if (error instanceof UnauthorizedError && retry) {
        // Do not retry if getTokenFromClown call fails
        if (await getTokenFromClown()) {
          return fnWithRetry(false, ...args)
        }
      } else if (error instanceof ForbiddenError) {
        window.location.href = '/404/'
      } else {
        throw error
      }
    }
  }
  return async (...args: any) => fnWithRetry(true, ...args)
}
