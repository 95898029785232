import '@propellerheads/ph-bootstrap/dist/5.0/css/ph-bootstrap.min.css'
import '../styles/bootstrap-overrides/alert.scss'
import '../styles/bootstrap-overrides/forms.scss'
import '../styles/bootstrap-overrides/modal.scss'
import '../styles/menu.scss'
import '../styles/header.scss'
import '../styles/main.scss'
import '../styles/footer.scss'

import * as Sentry from '@sentry/nextjs'
import { AppProps } from 'next/app'
import Layout from '../components/Layout'
import { NondescriptError } from '../components/NondescriptError'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import {
  getCoupons,
  getSubscriptions,
  getOrCreateRecurlyAccount,
} from '../services/SubscriptionApiClient'
import {
  UserContext,
  CouponContext,
  SubscriptionContext,
  RecurlyAccountContext,
} from '../components/context'
import {
  ReasonAccount,
  Coupon,
  SubscriptionType,
  RecurlyAccount,
} from '../types/types'
import { getReasonAccountInfo } from '../services/ClownApiClient'
import Spinner from '../components/Spinner'

function MyApp({ Component, pageProps }: AppProps) {
  const [reasonAccount, setReasonAccount] = useState<ReasonAccount | null>(null)
  const [recurlyAccount, setRecurlyaccount] = useState<
    RecurlyAccount | null | Error
  >(null)
  const [coupons, setCoupons] = useState<Coupon[]>([])
  const [subscription, setSubscription] = useState<
    SubscriptionType | null | Error
  >(null)
  const [subscriptionLoading, setSubscriptionLoading] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [error, setError] = useState<any>()

  useEffect(() => {
    async function getData() {
      try {
        setLoadingData(true)
        const account = await getReasonAccountInfo()
        account && setReasonAccount(account)
        if (account) {
          try {
            setRecurlyaccount(await getOrCreateRecurlyAccount(account))
          } catch (e) {
            setRecurlyaccount(e as Error)
          }
          try {
            setCoupons(await getCoupons())
            setSubscriptionLoading(true)
            const [subscription] = await getSubscriptions()
            setSubscription(subscription)
          } catch (e) {
            setSubscription(e as Error)
          }
          setSubscriptionLoading(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        console.error(err)
        setError(err)
      }
      setLoadingData(false)
    }
    getData()
  }, [])

  const updateCoupons = () => getCoupons().then(setCoupons)
  const updateSubscription = (subscription: SubscriptionType) =>
    setSubscription(subscription)
  const updateAccount = (account: RecurlyAccount) => setRecurlyaccount(account)

  return (
    <UserContext.Provider value={reasonAccount}>
      <CouponContext.Provider value={{ coupons, updateCoupons }}>
        <SubscriptionContext.Provider
          value={{
            subscription,
            updateSubscription,
            loading: subscriptionLoading,
          }}>
          <RecurlyAccountContext.Provider
            value={{ account: recurlyAccount, updateAccount }}>
            <Layout>
              <Script
                src="https://js.recurly.com/v4/recurly.js"
                strategy="afterInteractive"></Script>
              {loadingData && <Spinner />}

              {!loadingData && error && <NondescriptError />}

              {!loadingData && !error && <Component {...pageProps} />}
            </Layout>
          </RecurlyAccountContext.Provider>
        </SubscriptionContext.Provider>
      </CouponContext.Provider>
    </UserContext.Provider>
  )
}
export default MyApp
