import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { getIsAdmin } from '../services/TokenClient'

interface MenuLink {
  name: string
  href: string
  re: RegExp
  condition?: () => boolean
}

export default function Menu() {
  const router = useRouter()

  const reasonPlusPages: MenuLink[] = [
    {
      name: 'Plans and Payment',
      href: '/subscription',
      re: /\/(subscription|signup)(\/.*)?/,
    },
    { name: 'Coupons', href: '/coupons', re: /\/coupons(\/.*)?/ },
    { name: 'Receipts', href: '/receipts', re: /\/receipts(\/.*)?/ },
  ]
  const accountPages: MenuLink[] = [
    { name: 'Overview', href: '/', re: /\/$/ },
    { name: 'Profile', href: '/profile', re: /\/profile$/ },
    {
      name: 'Change Password',
      href: '/change-password',
      re: /\/change-password/,
    },
    {
      name: 'Admin',
      href: '/admin',
      re: /\/admin(\/.*)?/,
      condition: () => getIsAdmin(),
    },
    /*
    {
      name: 'Authorized Computers',
      href: '/authorized_computers',
      re: /\/authorized_computers$/,
    },
    */
  ]

  function makeSection(title: string, pages: MenuLink[]) {
    const isOpened = pages.map((p) => p.re).some((r) => r.test(router.pathname))
    if (!isOpened) {
      return (
        <div className={`menu-primary-nav`}>
          <Link href={pages[0].href}>
            <a>{title}</a>
          </Link>
        </div>
      )
    } else {
      return (
        <>
          <div className={`menu-primary-nav expanded highlighted`}>
            <a>{title}</a>
          </div>
          {pages
            .filter(({ condition }) => !condition || condition())
            .map(({ name, href, re }) => {
              return (
                <div
                  key={name}
                  className={`menu-primary-nav ${
                    re.test(router.pathname) ? 'active' : ''
                  } expanded`}>
                  <Link href={href}>
                    <a>
                      <span>{name}</span>
                    </a>
                  </Link>
                </div>
              )
            })}
        </>
      )
    }
  }

  return (
    <>
      <div className="menu d-lg-none mobile-menu">
        <Navbar bg="charcoal" expand="lg">
          <Container fluid>
            <div className="d-flex justify-content-between align-content-center">
              <Navbar.Toggle>
                <small style={{ color: 'white' }}>Browse</small>
                <svg
                  className="caret-down"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <path d="M7 10l5 5 5-5z" />
                </svg>
              </Navbar.Toggle>
              <div style={{ width: '50px' }}></div>
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <hr />
                {[...accountPages, ...reasonPlusPages].map((link) => {
                  return (
                    <Nav.Link href={link.href} key={link.name}>
                      <small>{link.name}</small>
                    </Nav.Link>
                  )
                })}
                <hr />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div id="sidebar-menu" className="menu d-none d-lg-block pt-60">
        {makeSection('Account', accountPages)}
        {makeSection('Reason+', reasonPlusPages)}
        <div className={`menu-primary-nav`}>
          <a href={`${process.env.NEXT_PUBLIC_CLOWN_URL}/user/products`}>
            My Products
          </a>
        </div>
      </div>
    </>
  )
}
