import Axios from 'axios'
import { getCookie } from '../utils'
import { getReasonAccountInfo } from './ClownApiClient'
import {
  ForbiddenError,
  NetworkError,
  RecurlyAccountNotFoundError,
  RecurlyTransactionError,
  UnauthorizedError,
} from './Errors'
import { requestWrapper } from './requestWrapper'
import { getJwt, getUserId } from './TokenClient'
import {
  CreateSubscriptionPayload,
  ReasonAccount,
  RecurlyAccount,
  SubscriptionTypeResponse,
} from '../types/types'

const SUBSCRIPTION_API = process.env.NEXT_PUBLIC_SUBSCRIPTION_API_URL

const subscriptionApiAxios = Axios.create({
  baseURL: SUBSCRIPTION_API,
  withCredentials: true,
})

subscriptionApiAxios.interceptors.request.use(
  function (config) {
    config.headers!['Authorization'] = `Bearer ${getJwt().accessToken}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

subscriptionApiAxios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (!error.response) {
      throw new NetworkError(error.message)
    }

    if (
      error.response.status === 404 &&
      error.response.data.type === 'not_found'
    ) {
      throw new RecurlyAccountNotFoundError(error.response.message)
    }
    if (
      error.response.status === 400 &&
      error.response.data.type === 'transaction'
    ) {
      throw new RecurlyTransactionError(error.response.data.message)
    }
    if (error.response.status === 401) {
      throw new UnauthorizedError()
    }
    if (error.response.status === 403) {
      throw new ForbiddenError()
    }

    return Promise.reject(new Error(error.response.data.message))
  },
)

// Subscription API endpoints
export const getOrCreateRecurlyAccount = async (clownInfo?: ReasonAccount) => {
  try {
    const tap_vid = getCookie('tap_vid')
    const account = await getRecurlyAccount()
    return account
  } catch (err) {
    if (err instanceof RecurlyAccountNotFoundError) {
      const _clownInfo = clownInfo || (await getReasonAccountInfo())
      const newAcc = await createRecurlyAccount(_clownInfo)
      return newAcc
    }
  }
}

export const createRecurlyAccount = requestWrapper(async (data) => {
  const tap_vid = getCookie('tap_vid')
  if (tap_vid) {
    data['acquisition'] = {
      channel: 'referral',
      subchannel: 'tapfilliate',
      campaign: tap_vid,
    }
  }
  const resp = await subscriptionApiAxios.post('/account/', {
    user_id: getUserId(),
    ...data,
  })
  return resp.data
})

export const getRecurlyAccount = requestWrapper(async () => {
  const resp = await subscriptionApiAxios.get<RecurlyAccount>(
    `/account/${getUserId()}`,
  )

  return resp.data
})

export const getSubscriptions = requestWrapper(async () => {
  try {
    return (
      await subscriptionApiAxios.get(
        `/account/${getUserId()}/subscriptions?state=live`,
      )
    ).data
  } catch (err) {
    if (err instanceof RecurlyAccountNotFoundError) {
      const clownInfo = await getReasonAccountInfo()
      const newAcc = await createRecurlyAccount(clownInfo)
      return []
    }
  }
})

export const getSubscription = requestWrapper(
  async (subscriptionId: string) => {
    return (
      await subscriptionApiAxios.get(
        `/account/${getUserId()}/subscriptions/${subscriptionId}`,
      )
    ).data
  },
)

export const createSubscription = requestWrapper(
  async (data: CreateSubscriptionPayload) => {
    const reqBody = {
      plan: data.plan,
      currency: data.currency,
      couponCode: data.couponCode,
      account: {
        billingInfo: data.billingInfo,
      },
    }
    const resp = await subscriptionApiAxios.post<SubscriptionTypeResponse>(
      `/account/${getUserId()}/subscriptions/new`,
      reqBody,
    )
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'new-subscription',
        eventCategory: 'new-subscription',
        eventAction: `${getUserId()}`,
        eventLabel: data.plan,
      })
    }
    return resp.data
  },
)

export const getPreviewRenewal = requestWrapper(
  async (subscriptionId: string) => {
    return (
      await subscriptionApiAxios.get(
        `/account/${getUserId()}/subscriptions/${subscriptionId}/preview-renewal`,
      )
    ).data
  },
)

interface tokenType {
  three_d_secure_action_result_token_id?: string
  token_id: string
}

export const updatePaymentInfo = requestWrapper(
  async (billingInfoToken: string, threeds: string) => {
    const data: tokenType = {
      token_id: billingInfoToken,
    }
    if (threeds) {
      data['three_d_secure_action_result_token_id'] = threeds
    }
    return (
      await subscriptionApiAxios.post(
        `/account/${getUserId()}/payment_method`,
        data,
      )
    ).data
  },
)

export const removeBillingDetails = requestWrapper(async () => {
  return (
    await subscriptionApiAxios.delete(`/account/${getUserId()}/payment_method`)
  ).data
})

export const cancelSubscription = requestWrapper(
  async (subscriptionId: string) => {
    return (
      await subscriptionApiAxios.put(
        `/account/${getUserId()}/subscriptions/${subscriptionId}/cancel`,
      )
    ).data
  },
)

export const reactivateSubscription = requestWrapper(
  async (subscriptionId: string) => {
    return (
      await subscriptionApiAxios.put(
        `/account/${getUserId()}/subscriptions/${subscriptionId}/reactivate`,
      )
    ).data
  },
)

export const createPendingChange = requestWrapper(
  async (
    subscriptionId: string,
    planChange: {
      planCode?: string
      couponCode?: string
      billingInfo?: { threeDSecureActionResultTokenId: string }
    },
  ) => {
    const resp = await subscriptionApiAxios.post(
      `/account/${getUserId()}/subscriptions/${subscriptionId}`,
      {
        planCode: 'reasonplusmonthly',
        ...planChange,
      },
    )
    return resp.data
  },
)

export const removePendingChange = requestWrapper(
  async (subscriptionId: string) => {
    return await subscriptionApiAxios.delete(
      `/account/${getUserId()}/subscriptions/${subscriptionId}`,
    )
  },
)

export const getInvoices = requestWrapper(async () => {
  return (await subscriptionApiAxios.get(`/account/${getUserId()}/invoices`))
    .data
})

export const getCoupons = requestWrapper(async (state: string) => {
  const couponState = state ? `?state=${state}` : ''
  return (
    await subscriptionApiAxios.get(
      `/account/${getUserId()}/coupons${couponState}`,
    )
  ).data
})

export const getInvoicePdf = requestWrapper(async (invoiceId: string) => {
  return (
    await subscriptionApiAxios.get(
      `/account/${getUserId()}/invoices/${invoiceId}/pdf`,
    )
  ).data
})

export const redeemCoupon = requestWrapper(async (couponCode: string) => {
  return (
    await subscriptionApiAxios.post(
      `/account/${getUserId()}/coupons/${couponCode}`,
      {
        couponId: couponCode,
      },
    )
  ).data
})

export const applyAntichurnCoupon = requestWrapper(async () => {
  return await subscriptionApiAxios.post(
    `/account/${getUserId()}/coupons/apply-antichurn-coupon/`,
  )
})

export const checkCouponValid = requestWrapper(async (couponCode: string) => {
  return (
    await subscriptionApiAxios.get(
      `/account/${getUserId()}/coupons/${couponCode}/check`,
    )
  ).data
})

export const getCoupon = requestWrapper(async (couponCode: string) => {
  return (
    await subscriptionApiAxios.get(
      `/account/${getUserId()}/coupons/${couponCode}`,
    )
  ).data
})
