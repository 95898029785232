export function NondescriptError(props: { message?: string }) {
  const { message } = props
  return (
    <div className="mt-40">
      <div className="text-center">
        <h4>Something went wrong!</h4>
      </div>
      <div className="text-center text-small">
        {message && <div>{message}</div>}
        Try refreshing the page and if the problem persists please contact{' '}
        <a href="https://help.reasonstudios.com/">support.</a>
      </div>
    </div>
  )
}
