import Head from 'next/head'
import Menu from './Menu'
import styles from '../styles/Layout.module.scss'
import Footer from './Footer'
import { SvgSymbols } from './SvgSymbols'
import Header from './Header'

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Head>
        <title>Reason Studios Account Management</title>
        <meta name="description" content="Reason Studios Account Management" />
        <meta content="utf-8" httpEquiv="encoding" />
        <link rel="icon" href="/favicon.ico" />
        <link
          href="https://js.recurly.com/v4/recurly.css"
          rel="stylesheet"
          type="text/css"></link>
      </Head>
      <SvgSymbols />
      <div className="bg-light-noise">
        <Header />
        <div className="container">
          <div className="row d-block">
            <div className="d-lg-flex bg-white">
              <div className="d-lg-flex flex-lg-shrink-0">
                <Menu />
              </div>
              <div className="d-flex w-100 justify-content-center">
                <div className={`${styles.main} p-20 p-lg-40`}>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
