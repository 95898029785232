import { createContext } from 'react'
import {
  ReasonAccount,
  Coupon,
  SubscriptionType,
  RecurlyAccount,
} from '../types/types'

export interface SubscriptionContextType {
  subscription: SubscriptionType | null | Error
  updateSubscription: (arg0: SubscriptionType) => void
  loading: boolean
}

export const RecurlyAccountContext = createContext<{
  account: RecurlyAccount | null | Error
  updateAccount: (arg0: RecurlyAccount) => void
}>({ account: null, updateAccount: () => {} })
export const UserContext = createContext<ReasonAccount | null>(null)
export const CouponContext = createContext<{
  coupons: Coupon[]
  updateCoupons: () => void
}>({ coupons: [], updateCoupons: () => {} })
export const SubscriptionContext = createContext<SubscriptionContextType>({
  subscription: null,
  updateSubscription: () => {},
  loading: true,
})
