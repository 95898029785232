import Axios from 'axios'
import { ReasonAccount, castToReasonAccount } from '../types/types'
import { resolve2LetterCountry } from '../utils'
import * as Sentry from '@sentry/nextjs'
import {
  ChangePasswordError,
  ClownTrialError,
  ForbiddenError,
  NetworkError,
  UnauthorizedError,
} from './Errors'
import { requestWrapper } from './requestWrapper'
import { getJwt, getUserId } from './TokenClient'

const clownApiAxios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_CLOWN_URL,
  withCredentials: true,
})

clownApiAxios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (!error.response) {
      throw new NetworkError(error.message)
    }
    if (error.response.status === 401) {
      throw new UnauthorizedError()
    }
    if (error.response.status === 403) {
      throw new ForbiddenError()
    }
    return Promise.reject(error)
  },
)

export const getReasonAccountInfo = requestWrapper(async () => {
  const data = (
    await clownApiAxios.get(`/api/account/${getUserId()}`, {
      headers: { Authorization: `Bearer ${getJwt().accessToken}` },
    })
  ).data
  if (data.country) {
    data.country = resolve2LetterCountry(data.country)
  }
  const meData = (
    await clownApiAxios.get(`/account/me`, {
      headers: {
        Authorization: `Bearer ${getJwt().accessToken}`,
      },
    })
  ).data
  data.currency = meData.currency || 'USD'
  data.hasTakenPlusTrial = meData.hasTakenPlusTrial || false
  return castToReasonAccount(data)
})

export const setReasonAccountInfo = requestWrapper(
  async (userInfo: ReasonAccount, passwordConfirmation: string) => {
    const resp = await clownApiAxios.put(
      `/api/account/${getUserId()}`,
      { ...userInfo, passwordConfirmation },
      { headers: { Authorization: `Bearer ${getJwt().accessToken}` } },
    )
    return castToReasonAccount(resp.data)
  },
)

export const addToTrialJourney = requestWrapper(async () => {
  try {
    await clownApiAxios.post('account/add-to-trial-journey')
  } catch {
    throw new ClownTrialError()
  }
})

export const checkPassword = async (password: string) => {
  return (
    await clownApiAxios.post('account/api/check/password', {
      user_id: getUserId(),
      password: password,
    })
  ).data
}

export const changePassword = requestWrapper(
  async (oldPassword: string, newPassword: string, confirmPassword: string) => {
    try {
      return (
        await clownApiAxios.put(
          `account/${getUserId()}/change-password`,
          {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
          },
          { headers: { Authorization: `Bearer ${getJwt().accessToken}` } },
        )
      ).data
    } catch (error) {
      if (Axios.isAxiosError(error) && error.response) {
        if (error.response.data.nonFieldErrors) {
          throw new ChangePasswordError(error.response.data.nonFieldErrors[0])
        } else if (error.response.data.oldPassword) {
          throw new ChangePasswordError(
            `Old Password: ${error.response.data.oldPassword[0]}`,
          )
        } else if (error.response.data.newPassword) {
          throw new ChangePasswordError(
            `New Password: ${error.response.data.newPassword[0]}`,
          )
        } else if (error.response.data.confirmPassword) {
          throw new ChangePasswordError(
            `Confirm Password: ${error.response.data.confirmPassword[0]}`,
          )
        } else {
          Sentry.captureException(error)
          throw new ChangePasswordError('An unexpected error occured')
        }
      } else {
        Sentry.captureException(error)
        throw new ChangePasswordError('An unexpected error occured')
      }
    }
  },
)

export const getKlaviyoStatus = async () => {
  return (await clownApiAxios.get('account/klaviyo-subscription')).data
}

export const subscribeUserToKlaviyoList = async () => {
  return (await clownApiAxios.put('account/klaviyo-subscription')).data
}

export const unsubscribeUserFromKlaviyoList = async () => {
  return (await clownApiAxios.delete('account/klaviyo-subscription')).data
}
